import * as React from "react";
import { Wrapper } from "./Magasin.module.css";
import { GrayTitle } from "./../../../css/Title.module.css";

const Magasin = () => {
  return (
    <div className={Wrapper}>
      <h1 className={GrayTitle}>Magasin</h1>
    </div>
  );
};

export default Magasin;
